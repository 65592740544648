


import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export const showAlert = (message,type) => {
  if(type=="success"){
    toast.success(message)
  }else{
    toast.error(message);
  }
           
    
  };