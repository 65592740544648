import axios from 'axios';
// Replace with your API base URL
// dev
// const BASE_URL = 'http://3.111.30.183:5000/'; 
// const BASE_URL = 'https://beemate.reapmind.com/'; 

// dev
// const BASE_URL = 'https://beemate.reapmind.com/vifi/'; 

// staging
const BASE_URL = 'https://api.vifi.ai/vifi/'; 

// Example of a GET request
export const getRequest = async (route) => {
  try {
    let config = {
        method: 'get',
        url: `${BASE_URL}${route}`,
        headers: {
            'Content-Type': 'application/json',
        }
    }
    
    if(localStorage.getItem('token')){
        config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    }

    const response = await axios(config);
    return response.data;

  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

// Example of a POST request
export const postRequest = async (route,data) => {
  try {
    let config = {
        method: 'post',
        url: `${BASE_URL}${route}`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: JSON.stringify(data)
    }
    
    if(localStorage.getItem('token')){
        config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    }

    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw {message:"Something went wrong"}
  }
};

// Example of a PUT request
export const putRequest = async (route,data) => {
  try {
    let config = {
        method: 'put',
        url: `${BASE_URL}${route}`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: JSON.stringify(data)
    }
    
    if(localStorage.getItem('token')){
        config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    }

    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error('Error putting data:', error);
    throw error;
  }
};

// Example of a DELETE request
export const deleteRequest = async (route) => {
  try {
    let config = {
        method: 'delete',
        url: `${BASE_URL}${route}`,
        headers: {
            'Content-Type': 'application/json',
        }
    }
    
    if(localStorage.getItem('token')){
        config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    }

    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error('Error deleting data:', error);
    throw error;
  }
};



// export default api;
