import React, { useEffect, useState } from 'react'
import "../Styles/scroll.css"


const MessageLoader = () => {

    const lyrics = [
        "Pay 5$ to a Bob",
        "What is my account balance?",
        "How much did I spend on food last month?",
        "Can you set my card limit to 5000$?",
        "What is the interest rate on Home loan?",
        "What is the difference between my expenses and income?",
        "How many cards do I have?",
        "Show me my last 5 transactions",
        "What is the balance in my deposit account?",
        "What is the credit limit for my card?",
        "Can you give me my account details?",
    ];
    const [counter, setCounter] = useState(0);
    const [animate, setAnimate] = useState(false);
    
    if(lyrics[counter]===undefined){
        setCounter(0)
    }
    // lyrics[counter] == "undefined" ? setCounter(0) : ""
    useEffect(() => {
        const intervalId = setInterval(() => {


            if (counter == lyrics.length) {
                setCounter(prevCounter => 0)
            }
            else {
                setCounter(prevCounter => prevCounter + 1);
            }

            setAnimate(true); // Trigger animation
            // This function will run every second
            // Update state or execute any side effect you want
            setTimeout(() => {
                setAnimate(false); // Turn off animation after a short delay
            }, 500);

        }, 4000); // 1000 milliseconds = 1 second
        // Cleanup function to clear the interval when the component unmounts or when dependencies change
        return () => clearInterval(intervalId);
    }, []);




    return (
        <div className='messagebox'>
            <div className=' animate fixedHeight' style={{ display: "flex", marginTop: "0px", marginBottom: "15px" }}>
                <span className={`opacity50 textCenter ${animate ? "animateIn" : ""}`}>
                    {lyrics.at(counter - 2)}
                </span>
            </div>
            <div className=' animate fixedHeight' style={{ display: "flex", marginTop: "15px", marginBottom: "15px" }}>
                <span className={`opacity70 textCenter ${animate ? "animateIn" : ""}`}>
                    {lyrics.at(counter - 1)}
                </span>
            </div>
            <div className={`MainLine`} style={{justifyContent:"center"}}>
                <p className={`textCenter ${animate ? " animateIn" : ""}`}>
                    {lyrics.at(counter)}
                </p>
            </div>
            <div className=' animate fixedHeight' style={{ display: "flex", marginTop: "15px", marginBottom: "15px" }} onAnimationEnds={() => console.log("")} >
                <span className={`opacity70 textCenter ${animate ? "animateIn" : ""}`}>
                    {lyrics.at(counter + 1) ? lyrics.at(counter + 1) : lyrics.at(0)}
                </span>
            </div>
            <div className=' animate fixedHeight' style={{ display: "flex", marginTop: "15px", marginBottom: "0px" }}>
                <span className={`opacity50 textCenter ${animate ? "animateIn" : ""}`}>
                    {lyrics.at(counter + 2) ? lyrics.at(counter + 2) : lyrics.at(1)}
                </span>
            </div>
        </div>
    )
}

export default MessageLoader