import React from 'react'
import { Grid } from '@mui/material';
import HomePage from './HomePage';
import Navbar from './Navbar';
const Layout = () => {
  return (
    <>
         <div className='App'>
          <Navbar/>
          <Grid container className='inputContainer' justifyContent={"center"} sx={{padding: { xs: '25px 16px 0px 16px ', sm: '',md:"30px 30px 20px 30px  ",lg:"30px 30px 20px 30px" }}}>
            <Grid item xs={12} sm={12} md={10} lg={10}>
                <HomePage/>
            </Grid>
        </Grid>
        </div>
    </>
  )
}

export default Layout