import axios from 'axios';
import Swal from "sweetalert2";
// Replace with your API base URL
// for live
export const BASE_URL = 'https://netbanking.vifi.ai/api/'; 
// for dev
//  export const BASE_URL = 'https://phpstack-906681-4392263.cloudwaysapps.com/api/';  

// Example of a GET request
export const GetRequest = async (route) => {
  try {
    let config = {
        method: 'get',
        url: `${BASE_URL}${route}`,
        headers: {
            'Content-Type': 'application/json',
        }
    }
    
    if(localStorage.getItem('token')){
        config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    }

    const response = await axios(config);
    return response.data;

  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

// Example of a POST request
export const PostRequest = async (route,data) => {
  try {
    let config = {
        method: 'post',
        url: `${BASE_URL}${route}`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: JSON.stringify(data)
    }
    
    if(localStorage.getItem('token')){
        config.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('token'))}`
    }

    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw {message:"Something went wrong"}
  }
};

// Example of a PUT request
export const PutRequest = async (route,data) => {
  try {
    let config = {
        method: 'put',
        url: `${BASE_URL}${route}`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: JSON.stringify(data)
    }
    
    if(localStorage.getItem('token')){
        config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    }

    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error('Error putting data:', error);
    throw error;
  }
};

// Example of a DELETE request
export const DeleteRequest = async (route) => {
  try {
    let config = {
        method: 'delete',
        url: `${BASE_URL}${route}`,
        headers: {
            'Content-Type': 'application/json',
        }
    }
    
    if(localStorage.getItem('token')){
        config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    }

    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error('Error deleting data:', error);
    throw error;
  }
};


export function _showAlertSuccess(text, title = "") {
  return Swal.fire({
      icon: 'success',
      title: text,
      text: title,
      confirmButtonColor: "#28a745",
      timer: 2000,
      timerProgressBar: true,
  });
}


// export default api;
