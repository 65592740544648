
export const fadeIn = ( delay, duration) => ( {
  hidden: {
    opacity: 0,
    y: "100%", // Start from the bottom
  },
  visible: {
    opacity: 1,
    y: 0, // Move to the original position (top)
    transition: {
      duration: duration,
      type: "tween",
      delay: delay, // Delay the animation by 0.3 seconds
    },
  },
});

