import React, { useState, useRef, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import {
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Backdrop,
  CircularProgress,
  Box,
  Button,
  Card,
} from "@mui/material";
import { motion } from "framer-motion";
import SendIcon from "@mui/icons-material/Send";
import { fadeIn } from "../motion";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import { postRequest } from "../api";
import pdfIcon from  "../Assets/pdfIcon.svg";
import donwloadIcon from  "../Assets/donwload.svg";

import chatRightIcon from  "../Assets/chat_Icon.png";
import { BASE_URL, PostRequest, _showAlertSuccess } from "../apiService";
import moment from 'moment';
import { showAlert } from "../ErrorHanding";
import BeatLoader from "react-spinners/BeatLoader";
import { useNavigate } from "react-router-dom";
import MessageLoader from "../Components/MessageLoader";


const HomePage = () => {
  const messagesEndRef = useRef(null);
  const audioRef = useRef(null);
  const navigate=useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [messages, setMessages] = useState([]);
  const [isSpeechRecognitionStarted, setIsSpeechRecognitionStarted] =useState(false);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [audioUrl,setAudioUrl]=useState(null);
  const[isPlay,setIsPlay]=useState(false);
  const [open,setOpen]=useState(false);
  const [threadId, setThreadId] = useState("");
  const [assistantId, setAssistantId] = useState("");
  const [selectionType, setSelectionType] = useState("");
  const [timerExpired, setTimerExpired] = useState(false);
  const userData=JSON.parse(localStorage.getItem("logindata"));
  const token =JSON.parse(localStorage.getItem("token"));
  const scrollToBottom = () => {
    setTimeout(()=>{
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    },0)
  };

  
 
  
  // Function to set the timer
  const setTimer = (duration) => {
    setTimeout(() => {
      // Execute this function after the specified duration
      console.log("Timer is up!");
      // Save the timer value to localStorage
      localStorage.removeItem('timerStart'); // Clear the timerStart when timer expires
      localStorage.setItem('timerExpired', true);
      // Update the state to reflect that the timer has expired
      setTimerExpired(true);
    }, duration);
  }

  useEffect(() => {
    const durationInMinutes = 60;
    const durationInMilliseconds = durationInMinutes * 60 * 1000;

    // Check if there's a stored timer start time
    const timerStart = localStorage.getItem('timerStart');
    if (timerStart) {
      // Calculate the remaining time
      const elapsedTime = Date.now() - parseInt(timerStart, 10);
      const remainingTime = durationInMilliseconds - elapsedTime;
      if (remainingTime > 0) {
        // Set timer with remaining time
        setTimer(remainingTime);
      } else {
        // Timer expired, set the state accordingly
        localStorage.removeItem('timerStart');
        localStorage.setItem('timerExpired', true);
        setTimerExpired(true);
      }
    } else {
      // No stored timer, set a new timer
      localStorage.setItem('timerStart', Date.now().toString());
      setTimer(durationInMilliseconds);
    }

    return () => {
      clearTimeout(); 
    };
  }, []); 

  useEffect(() => {
    if (timerExpired) {
      setOpen(true);
      // Perform any action on timer expiry
      // webLogout();
    }
  }, [timerExpired]);

  const handleItemClick = (selectedItem,type) => {
    setActiveItem(selectedItem);
    sendMessage(type==="Beneficiary_Details"?selectedItem.Account_Name:selectedItem.Account_Number, "user");
    verifyEmail( type==="Beneficiary_Details"?selectedItem.Account_Name:selectedItem.Account_Number);
  };
  const handleCreditLimit = (selectedItem,type) => {
    setActiveItem(selectedItem);
    sendMessage(type==="Credit_Card_Details"?selectedItem.Card_Number:null, "user");
    verifyEmail( type==="Credit_Card_Details"?selectedItem.Card_Number:selectedItem.Account_Number);

  };
  const handleAccountData = (selectedItem,type) => {
    setSelectedAccount(selectedItem);
    sendMessage(type==="Beneficiary_Details"?selectedItem.Account_Name:selectedItem.Account_Number, "user");
    verifyEmail( type==="Beneficiary_Details"?selectedItem.Account_Name:selectedItem.Account_Number);
  };
  const handleCancelClick = () => {
    sendMessage("Cancel", "user");
    verifyEmail( "Cancel");
  };

const handlePayClick=(msg)=>{
  // sendMessage("Pay", "user");
  verifyEmail( msg);
}

  const playAudio = () => {
    setTimeout(()=>{
      if (audioRef?.current) {
        audioRef?.current.play();
        setIsPlay(true);
      }else {
        console.error('Audio reference is not set.');
      }
    },0)
  };
 

  const pauseAudio = () => {
    audioRef.current.pause();
    setIsPlay(false);
  };

  const handleLogoutBtn=()=>{
    setOpen(false);
    setTimeout(() => {
      window.location.href = "/"; 
      localStorage.clear();
    }, 1000);
}

  useEffect(() => {
    scrollToBottom();
  }, [messages]);



  const sendMessage = (
    msg,
    type,
    selectionType,
    Output_List,
    Account_list,
    Beneficiary_list,
    Confirmation_List,
    File_URL,
    Selection_List
  ) => {
    const newMessage = {
      id: msg.length + 1,
      text: msg,
      sender: type,
      selectionType: selectionType,
      OutputList: Output_List,
      Account_list: Account_list,
      Beneficiarylist:Beneficiary_list,
      ConfirmationList:Confirmation_List,
      FileURL:File_URL,
      SelectionList:Selection_List,
    };

    let newM = messages;
    newM.push(newMessage);
    scrollToBottom();
    setMessages(newM);
    setSearchValue("");
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if(searchValue.trim() !== "" && !loader){
          sendMessage(searchValue, "user");
          verifyEmail(searchValue);
      }
  }
  };

  const handleSendBtn=()=>{
    if(searchValue.trim() !== "" && !loader){
      sendMessage(searchValue, "user");
      verifyEmail(searchValue);
  }

  }




  function getLocalStream() {
    navigator.mediaDevices
      .getUserMedia({ video: false, audio: true })
      .then((stream) => {
        window.localStream = stream; // A
        window.localAudio.srcObject = stream; // B
        window.localAudio.autoplay = true; // C
      })
      .catch((err) => {
        console.error(`you got an error: ${err}`);
      });
  }
  

  useEffect(()=>{
    getLocalStream();
  },[])

  // Initialize SpeechRecognition
  const recognition = new window.webkitSpeechRecognition();
  recognition.continuous = false;
  recognition.interimResults = false;
  // Set the language for recognition
  recognition.lang = "en-US";

  // Function to start speech recognition
  const startSpeechRecognition = () => {
  
    if (!isSpeechRecognitionStarted) {
      recognition.start();
      setIsSpeechRecognitionStarted(true);
    }
  };

  // Event handlers for speech recognition
  recognition.onresult = (event) => {
    const speechToText = event.results[0][0].transcript;
    setSearchValue(speechToText);
  };

  recognition.onspeechend = () => {
    recognition.stop();
    setIsSpeechRecognitionStarted(false);
  };


  
  recognition.onerror = (event) => {
    console.error("Speech recognition error:", event.error);
    setIsSpeechRecognitionStarted(false);
  };

  const AccountDetailsApi = async (searchValue) => {
    const endPoint = "conversation";
    const payload = {
      query: searchValue,
      Thread_ID: threadId,
      assistant_ID: assistantId,
      // User_ID: '181',
      User_ID: userData.data.user.id,
      context_value: "",
    };
    try {
      setLoader(true);
      let response = await postRequest(endPoint, payload);
      let finalResponse = response.response;
      setLoader(false);
      setData(finalResponse);
      sendMessage(
        finalResponse.AI_RESPONSE,
        "AI",
        finalResponse.Selection_Type,
        finalResponse.Output_List,
        finalResponse.Account_list,
        finalResponse.Beneficiary_list,
        finalResponse.Confirmation_List,
        finalResponse.File_URL,
        finalResponse.Selection_List,
      );
      setThreadId(finalResponse.Thread_ID);
      setAssistantId(finalResponse.assistant_ID);
      setSelectionType(finalResponse.Selection_Type);
      setAudioUrl(finalResponse.Audio_File)
      playAudio(finalResponse?.Audio_File);
      scrollToBottom();
    } catch (error) {
      setLoader(false);
      console.error("Error occurred during API call:", error);
      showAlert("Something  went wrong","error");
    }
  };


  // const sendPaymentAPi=async(paymentData)=>{
  //   const endPoint = `own/transfer/request/${paymentData.beneficiary_id}`;
  //   const payload = {
  //     "amount": paymentData.amount,
  //     "auth_mode": "email",
  //     "account_id":selectedAccount.Account_Number,
  //     "type":"chat"
  //   };
  //   try {
  //     setLoader(true);
  //     let response = await PostRequest(endPoint, payload);
      
  //     let finalResponse = response;
  //     if(finalResponse.status==="error"){
  //       setLoader(false);
  //     }else{
  //       console.log('finalResponse pay:', finalResponse);
  //       handlePayClick(finalResponse?.message);
  //       setLoader(false);
  //     }
    
  //   } catch (error) {
  //     setLoader(false);
  //     console.error("Error occurred during API call:", error);
  //     showAlert("Something  went wrong","error");
  //   }
  // }

  const sendPaymentAPi = async (paymentData) => {
    const endPoint = `${BASE_URL}own/transfer/request/${paymentData.beneficiary_id}`;
    const payload = {
        "amount": paymentData.amount,
        "auth_mode": "email",
        "account_id": selectedAccount.Account_Number,
        "type": "chat"
    };

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
            // Add any additional headers here if needed
        },
        body: JSON.stringify(payload)
    };

    try {
        setLoader(true);
        const response = await fetch(endPoint, requestOptions);
        const responseData = await response.json();

        if (responseData.status==="success") {
            setLoader(false);
            await handlePayClick(responseData?.message);
        } else {
            setLoader(false);
            setOpen(true);
            // navigate("/");
            console.error('Error occurred during API call:', responseData);
            // showAlert("Something went wrong", "error");
        }

        
    } catch (error) {
        console.error("Error occurred during API call:", error);
        showAlert("Something went wrong", "error");
        setLoader(false);
    }
};



  const verifyEmail=async(searchValue)=>{
    const endPoint = `${BASE_URL}verify-email`;
    const codeno="000000";
    const payload = {
        "code": +codeno,
    };

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
            // Add any additional headers here if needed
        },
        body: JSON.stringify(payload)
    };

    try {
        setLoader(true);
        const response = await fetch(endPoint, requestOptions);
        const responseData = await response.json();
        console.log('responseData :', responseData);

        if (responseData.status==="success") {
            setLoader(false);
            await AccountDetailsApi(searchValue);

        } else {
            setLoader(false);
            setOpen(true);
            // navigate("/");
            console.error('Error occurred during API call:', responseData);
            // showAlert("Something went wrong", "error");
        }

        
    } catch (error) {
        console.error("Error occurred during API call:", error);
        showAlert("Something went wrong", "error");
        setLoader(false);
    }
  }

  // useEffect(()=>{
  //   verifyEmail();
  // },[])

  const  webLogout=async()=>{
    const endPoint = `${BASE_URL}web-logout`;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
            // Add any additional headers here if needed
        },
    };

    try {
        const response = await fetch(endPoint, requestOptions);
        const responseData = await response.json();
    } catch (error) {
        console.error("Error occurred during API call:", error);
        showAlert("Something went wrong", "error");
    }
  }
  return (
    <>

   <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <Card className='verified__card'>
            
            <div>
                <h3 className='popup__title'>We are signing you out</h3>
                <p className='popup__descriptions'>Your account has been logged in on another device</p>
            </div>
            <Button sx={{marginTop:"1.2rem"}} onClick={handleLogoutBtn} className='login-btn'>Log Out</Button>
        </Card>
      </Backdrop>
       <ToastContainer 
        position="top-right"
        autoClose={1100}
        />

        
       <audio ref={audioRef} src={audioUrl} />

       {messages.length == 0 ? <MessageLoader /> : ""}

      <div className="overflow__box" >

        {messages.map((message) => (
          <div
         
            className="messages-container"
            style={{
              alignItems: message.sender === "AI" ? "flex-start" : "flex-end",
            }}
          >
            <motion.div
             
              key={message.id}
              className={`message ${message.sender}`}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: -20 }}
              transition={{ duration: 0.5 }}
            >
              {message.selectionType === "" && message.sender === "AI" ? (
                <div
                  style={{
                    display: "flex",
                    alignItems:"start",
                    gap: "12px",
                    flexDirection:
                      message.sender === "AI" ? "row-reverse" : "inherit",
                  }}
                >
                  <div className="msg-heading-2">
                    <span className="msg-text">{message.text}</span>
                  </div>
                  {/* <div className="sender-icons">VS</div> */}
                  <img className="chat__Icon" src={chatRightIcon} alt="chatIcon"/>
                </div>
              ) : message.selectionType === "Transaction_List" &&
                message.sender === "AI" ? (
                <>
                  <div
                   className="transitions__details_container"
                    style={{
                      display: "flex",
                      alignItems:"start",
                      gap: "12px",
                      flexDirection:
                        message.sender === "AI" ? "row-reverse" : "inherit",
                    }}
                  >
                    <div className="transitions__details__Box">
                    <span className="msg-text">{message.text}</span>
                      {message.OutputList.map((ele) => {
                        return (
                          <>
                          <div className="transactions__boxes" style={{cursor:"default"}}>
                            <Grid
                              container
                              justifyContent={"space-between"}
                              alignItems={"center"}
                            >
                              <Grid item xs={7} sm={8} md={8} lg={8}>
                                <Grid container spacing={2} alignItems={"center"}>
                                  <Grid className="sernder__icons_Mobile" item xs={3} sm={3} md={3} lg={3}>
                                    <div className="sender-icons">{ele.Beneficiary_Name[0]}</div>
                                  </Grid>
                                  <Grid item xs={12} sm={9} md={9} lg={9}>
                                    <Box flexDirection={"column"}>
                                      <p className="transitions__details">
                                        {ele.Beneficiary_Name}
                                      </p>
                                      <p className="transitions__details_date">
                                        {moment(ele.Transaction_date, 'YYYY-MM-DD HH:mm:ss').format('DD MMMM YYYY')}
                                      </p>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={5} sm={4} md={4} lg={4}>
                                <Box flexDirection={"column"}>
                                  <p className="transitions__details__amount">
                                     {parseFloat(ele.Transaction_Amount).toFixed(2)} USD
                                  </p>
                                  <p className={`transitions__details__status  ${ele.Transaction_Status==="Completed" ?"color__of__type__green"  :"color__of__type__red"}`}>
                                    {ele.Transaction_Status}
                                  </p>
                                </Box>
                              </Grid>
                            </Grid>
                          </div>
                           
                           </>
                        );
                      })}
                      {/* <Box display={"flex"} justifyContent={"end"} sx={{marginTop:"12px",}}>
                        {
                          isPlay?(
                            <PauseIcon onClick={pauseAudio} sx={{cursor:"pointer",color:""}}/> 
                          ):(
                            <PlayArrowIcon onClick={playAudio} sx={{cursor:"pointer",color:""}}/> 
                          )
                        }
                         
                        
                      </Box> */}
                    </div>
                    {/* <div className="sender-icons">VS</div> */}
                    <img className="chat__Icon" src={chatRightIcon} alt="chatIcon"/>
                  </div>
                </>
              ) : message.selectionType === "Account_Details" &&
                message.sender === "AI" ? (
                <>
                  <div
                  className="transitions__details_container"
                    style={{
                      display: "flex",
                      alignItems:"start",
                      gap: "12px",
                      flexDirection:
                        message.sender === "AI" ? "row-reverse" : "inherit",
                    }}
                  >
                    <div className="transitions__details__Box">
                    <span className="msg-text">{message.text}</span>
                      {message.Account_list.map((ele, index) => {
                        return (
                          <div
                            className={`transactions__boxes ${
                              selectedAccount === ele ? "active" : ""
                            }`}
                            key={index}
                            onClick={() => handleAccountData(ele)}
                          >
                            <Grid
                              container
                              justifyContent={"space-between"}
                              alignItems={"center"}
                            >
                              <Grid item xs={8} sm={4} md={4} lg={5}>
                                <Box flexDirection={"column"}>
                                  <p className="transitions__details__name">
                                    {ele.Account_Name}
                                  </p>
                                  <p className="transitions__details__account__No">
                                    {ele.Account_Number}
                                  </p>
                                  <p className="transitions__details__account__type">
                                    {ele.Account_Type.replace(/_/g, " ")}
                                  </p>
                                </Box>
                              </Grid>
                              <Grid item xs={4} sm={3} md={3} lg={4}>
                                <Box flexDirection={"column"}>
                                  <p className="transitions__details__balence">
                                  
                                     {parseFloat(ele.Account_balance).toFixed(2)}USD
                                  </p>
                                  {/* <p className="transitions__details__status">
                                    {ele.Transaction_Status}
                                  </p> */}
                                   <button className="Select__btn">Select</button>
                                </Box>
                              </Grid>
                            </Grid>
                          </div>
                        );
                      })}
                    </div>
                    <img className="chat__Icon" src={chatRightIcon} alt="chatIcon"/>
                  </div>
                  
                </>
              ) : message.selectionType === "Beneficiary_Details" &&
                message.sender === "AI" ? (
                <>
                  <div
                   className="transitions__details_container"
                    style={{
                      display: "flex",
                      alignItems:"start",
                      gap: "12px",
                      flexDirection:
                        message.sender === "AI" ? "row-reverse" : "inherit",
                    }}
                  >
                    <div className="transitions__details__Box">
                    <span className="msg-text">{message.text}</span>
                      {message.Beneficiarylist.map((ele, index) => {
                        return (
                          <div
                            className={`transactions__boxes ${
                              activeItem === ele ? "active" : ""
                            }`}
                            key={index}
                            onClick={() => handleItemClick(ele,message.selectionType)}
                          >
                            <Grid
                              container
                              justifyContent={"space-between"}
                              alignItems={"center"}
                            >
                              <Grid item xs={8} sm={4} md={4} lg={4}>
                                <Box flexDirection={"column"}>
                                  <p className="transitions__details__name">
                                    {ele.Account_Name}
                                  </p>
                                  <p className="transitions__details__account__No">
                                    {ele.Account_Number}
                                  </p>
                                  <p className="transitions__details__account__type">
                                    {ele.Account_Type.replace(/_/g, " ")}
                                  </p>
                                </Box>
                              </Grid>
                              <Grid item xs={4} sm={3} md={3} lg={3}>
                                <Box flexDirection={"column"}>
                                  {/* <p className="transitions__details__balence">
                                     {ele.Account_balance} USD
                                  </p>
                                  <p className="transitions__details__status">
                                    {ele.Transaction_Status}
                                  </p> */}
                                  <button className="Select__btn">Select</button>
                                </Box>
                              </Grid>
                            </Grid>
                          </div>
                        );
                      })}
                    </div>
                    <img className="chat__Icon" src={chatRightIcon} alt="chatIcon"/>
                  </div>
                </>
              ) : message.selectionType==="Confirmation_List"  &&  message.sender === "AI"?  (
                <>
                <div
                className="transitions__details_container"
                  style={{
                    display: "flex",
                    gap: "12px",
                    alignItems:"start",
                    flexDirection:
                      message.sender === "AI" ? "row-reverse" : "inherit",
                  }}
                >
                 <div className="transitions__details__Box">
                  <span className="msg-text">{message.text}</span>
                    {message.ConfirmationList.map((ele, index) => {
                      return (
                        <div
                          className={`transactions__boxes`}
                          key={index}
                        >
                          <Grid
                            container
                            justifyContent={"space-between"}
                            alignItems={"center"}
                          >
                            <Grid item xs={8} sm={4} md={4} lg={4}>
                              <Box flexDirection={"column"}>
                                <p className="transitions__details__name">
                                  {ele.beneficiary_name}
                                </p>
                                <p className="transitions__details__account__No">
                                  {ele.user_account_number}
                                </p>
                                <button className="cancel__btn" onClick={()=>{ handleCancelClick()}}>Cancel</button>
                              </Box>
                            </Grid>
                            <Grid item xs={4} sm={3} md={3} lg={3}>
                              <Box flexDirection={"column"}>
                                <p className="transitions__details__balence">
                                   {ele.amount} USD
                                </p>
                                <p className="transitions__details__status">
                                  {ele.user_accoun_type}
                                </p>
                                <button onClick={()=>{sendPaymentAPi(ele)}} className="pay__btn">Pay</button>
                              </Box>
                            </Grid>
                          </Grid>
                        </div>
                      );
                    })}
                  </div>
                  <img className="chat__Icon" src={chatRightIcon} alt="chatIcon"/>
                </div>
              </>
              ): message.selectionType==="Credit_Card_Details"  &&  message.sender === "AI"? (
                <>
                 <div
                   className="transitions__details_container"
                    style={{
                      display: "flex",
                      alignItems:"start",
                      gap: "12px",
                      flexDirection:
                        message.sender === "AI" ? "row-reverse" : "inherit",
                    }}
                  >
                    <div className="transitions__details__Box">
                    <span className="msg-text">{message.text}</span>
                      {message.SelectionList.map((ele, index) => {
                        return (
                          <div
                            className={`transactions__boxes ${
                              activeItem === ele ? "active" : ""
                            }`}
                            key={index}
                            onClick={() => handleCreditLimit(ele,message.selectionType)}
                          >
                            <Grid
                              container
                              justifyContent={"space-between"}
                              alignItems={"center"}
                            >
                              <Grid item xs={8} sm={4} md={4} lg={4}>
                                <Box flexDirection={"column"}>
                                  <p className="transitions__details__name">
                                    {ele.Cardholder_Name}
                                  </p>
                                  <p className="transitions__details__account__No">
                                    {ele.Card_Number.replace(/\D/g, '').replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, '$1  $2  $3  $4')}
                                  </p>
                                  <p className="transitions__details__account__type">
                                    {ele.Card_Type}
                                  </p>
                                </Box>
                              </Grid>
                              <Grid item xs={4} sm={3} md={3} lg={3}>
                                <Box flexDirection={"column"}>
                                   <p className="transitions__details__balence">
                                     {ele.Card_Withdrawal_Limit} USD
                                  </p>
                                  <button className="Select__btn">Select</button>
                                </Box>
                              </Grid>
                            </Grid>
                          </div>
                        );
                      })}
                    </div>
                    <img className="chat__Icon" src={chatRightIcon} alt="chatIcon"/>
                  </div>
                </>
              ):message.selectionType==="PDF_FILE"  &&  message.sender === "AI"? (
                <>
                <div
                className="transitions__details_container"
                  style={{
                    display: "flex",
                    alignItems:"start",
                    gap: "12px",
                    flexDirection:
                      message.sender === "AI" ? "row-reverse" : "inherit",
                  }}
                >
                  <div className="transitions__details__Box">
                    <span className="msg-text">{message.text}</span>
                    {message.FileURL.map((ele, index) => {
                      return (
                        <div
                          className={`transactions__boxes_Fileurl`}
                          key={index}
                        >
                          <Grid container sx={{alignItems:"center"}}>
                              <Grid item xs={2} sm={3} md={3} lg={2}>
                                  <img width={"20px"} height={"20px"}  src={pdfIcon} alt="pdf-icon"/>
                              </Grid>
                              <Grid item xs={8} sm={6} md={6} lg={8}>
                              <p className="transitions__details__name">
                              Last 20 Transactions 
                             </p>
                             <p className="transitions__details__account__type">PDF &lt; 2 MB</p>
                              </Grid>
                               <Grid item xs={2} sm={3} md={3} lg={2}>
                                 <a href={ele} target="_blank">
                                 <img width={"30px"} height={"30px"} style={{cursor:"pointer"}} src={donwloadIcon} alt="download-icon"/>
                                 </a>
                              </Grid>
                          </Grid>
                        </div>
                      );
                    })}
                  </div>
                  <img className="chat__Icon" src={chatRightIcon} alt="chatIcon"/>
                </div>
              </>
              ) :message.sender === "user" ? (
                <div
                  style={{
                    display: "flex",
                    gap: "12px",
                    flexDirection:
                      message.sender === "AI" ? "row-reverse" : "inherit",
                  }}
                >
                  <div className="msg-heading-1">
                    <span className="msg-text">{message.text}</span>
                  </div>
                  <div className="sender-icons">{userData.data.user.firstname[0]}</div>
                </div>
                
              ) : null}
            </motion.div>
          </div>

          
        ))}
            {
      loader && (
        <div
        // className="messages-container"
        style={{
          alignItems:"flex-start" 
        }}
      >
        <motion.div
          // className={`message`}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: -20 }}
          transition={{ duration: 0.5 }}
        >
           <div className="spinner__box">
             <BeatLoader size={10} />
           </div>
        </motion.div>
        </div>
      )
    }
    <div ref={messagesEndRef} />
      </div>
      {/* <div ref={messagesEndRef} /> */}

      <motion.div
        initial="hidden"
        animate="visible"
        variants={fadeIn(0.3, 0.5)}
      >
        <Grid container spacing={1} sx={{ alignItems: "center" }}>
          <Grid item xs={10} sm={10} md={11} lg={11}>
            <TextField
              sx={{
                width: "100%",
                marginTop: "10px",
                boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)', 
                borderRadius:"15px",
                "& input::placeholder": {
                  fontWeight: 500,
                  fontSize:"14px",
                  color:"rgba(156, 156, 156, 1)"
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderWidth: 0, // No border initially
                  },
                  '&:hover fieldset': {
                    borderWidth: 0, // No border on hover
                  },
                  '&.Mui-focused fieldset': {
                    borderWidth: 0, // No border when focused
                  },
                },
              }}
              id="outlined-basic"
              label="Type your message here ...."
              variant="outlined"
              autoComplete="off"
            InputLabelProps={{
                style: { fontSize: "14px",fontFamily:"Montserrat" },
            }}
              size="medium"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyPress={handleKeyPress}
              InputProps={{
                style: { borderRadius: "10px", fontSize: "14px" },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      
                      disabled={isSpeechRecognitionStarted}
                      onClick={startSpeechRecognition}
                    >
                      {
                        isSpeechRecognitionStarted
                      }
                      <KeyboardVoiceIcon sx={{color:`${isSpeechRecognitionStarted?"red":""}`}} />
                    </IconButton>
                  </InputAdornment>
                ),

              }}
              // InputLabelProps={{
              //   style: { color: "#fff" },
              // }}
            />
          </Grid>
          <Grid item xs={2} sm={2} md={1} lg={1}>
            <IconButton
             disabled={loader}
              sx={{
                marginTop:"10px",
                cursor:"pointer",
                backgroundColor: "rgba(164, 59, 193, 1)",
                "&:hover": {
                  backgroundColor: "rgba(164, 59, 193, 1)",
                },
              }}
              onClick={() => {
                handleSendBtn();
                
              }}
            >
             
              <SendIcon sx={{ color:loader? "black":"#fff" }} />
            
             
              
            </IconButton>
          </Grid>
        </Grid>
      </motion.div>
    </>
  );
};

export default HomePage;
