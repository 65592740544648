import React from 'react';
import { Avatar, Box } from '@mui/material'
import logo from  "../Assets/logo.png";
import mobileLogo from  "../Assets/chat_Icon.png";
import "../Styles/Navbar.css";
const Navbar = () => {

  const userDetails=JSON.parse(localStorage.getItem("logindata"));

  return (
    <>
        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} className="navbar__box" >
           <Box>
           <img className='logo' src={logo} alt='logo' />
            <img className='mobileLogo' src={mobileLogo} alt='logo' />
           </Box>
            <Box sx={{marginRight:"20px"}}>
                <Box display={"flex"} alignItems={"center"} className="Box__End__part__Nav">
                  <p className='navbar__descriptions_web'>Hey <span className='name__user'>{userDetails.data.user.firstname}</span> Welcome to your demo account</p>
                  <p className='navbar__descriptions_mob'>Hey <span className='name__user'>{userDetails.data.user.firstname}</span><br/> Welcome to your demo account</p>
                  <Avatar alt="Remy Sharp" className='profile_image' src={userDetails.data.user.profile_image} />
                </Box>
            </Box>

        </Box>
    </>
  )
}

export default Navbar