import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Backdrop,
  Button,
  Card,
  Grid,
  TextField,
  Box,
  MenuItem,
  Autocomplete,
  Paper,
} from "@mui/material";
import { PostRequest, _showAlertSuccess } from "../apiService";
import { useNavigate } from "react-router-dom";
import verifiedCheck from "../Assets/solar_verified-check.svg";
import loginGif from "../Assets/LoginRightSide.gif";

import "../Styles/Login.css";
import { showAlert } from "../ErrorHanding";
import LoginFirstScreen from "./LoginFirstScreen";
import LoginSecondPage from "./LoginSecondPage";
import { countries } from "./CountryCode";

const Login = () => {
  const [data, setData] = useState("");
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);

  const onSubmit = (data) => {
    LoginAPi(data);
  };

  const LoginAPi = async (data) => {
    const endPoint = `web-login`;
    const payload = {
      full_name: data.username,
      organization:data.organization,
      mobile_number: `${selectedCountry.code} ${data.phoneNo}`,
      email: data.email,
    };
    try {
      setLoader(true);
      let response = await PostRequest(endPoint, payload);
      let finalResponse = response;
      setData(finalResponse.data.user.firstname);
      localStorage.clear();
      localStorage.setItem(
        "token",
        JSON.stringify(finalResponse.data.access_token)
      );
      localStorage.setItem("logindata", JSON.stringify(finalResponse));
      setOpen(true);
      setTimeout(() => {
        navigate("/chatbot");
        setOpen(false);
      }, 1000);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      setOpen(false);
      console.error("Error occurred during API call:", error);
      showAlert("Something  went wrong", "error");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <Card className="verified__card">
          <img src={verifiedCheck} alt="verifiedCheck" />
          <div>
            <h3 className="popup__title">Successful !</h3>
            <p className="popup__descriptions">
              You are successfully logged in as{" "}
              <span className="name__user">{data}</span> for <br /> demo
              purpose.
            </p>
          </div>
        </Card>
      </Backdrop>
      <Grid container className="login-container" justifyContent={"center"}>
        <Grid item xs={12} sm={10} md={10} lg={10}>
          <Grid container justifyContent={"center"}>
            <Grid
              item
              xs={12}
              sm={5}
              md={5}
              lg={5}
              className="left__side__form"
            >
              <h3 className="login__title">
                Experience <span className="login__sub__title">Vifi AI</span>
              </h3>
              <p className="login__descriptions">
                Welcome! Please enter your details to <br /> to experience the
                demo.
              </p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid
                  container
                  sx={{ justifyContent: "start", marginTop: "2rem" }}
                >
                  <Grid
                    sx={{ marginBottom: "0.6rem" }}
                    item
                    xs={12}
                    sm={9}
                    md={9}
                    lg={9}
                  >
                    <p className="full__name">Full Name</p>
                    <TextField
                      size={"small"}
                      sx={{
                        width: "100%",
                        borderRadius: "15px",
                      }}
                      id="outlined-basic"
                      label="Enter your full name.."
                      variant="outlined"
                      autoComplete="off"
                      InputProps={{
                        style: { borderRadius: "10px", fontSize: "14px" },
                      }}
                      InputLabelProps={{
                        style: { fontSize: "14px", fontFamily: "Montserrat" },
                      }}
                      {...register("username", { required: true })}
                      error={errors.username ? true : false}
                      helperText={errors.username && "Full Name is required"}
                    />
                  </Grid>
                  <Grid
                    sx={{ marginBottom: "0.6rem" }}
                    item
                    xs={12}
                    sm={9}
                    md={9}
                    lg={9}
                  >
                    <p className="email">Email</p>
                    <TextField
                      size={"small"}
                      type="email"
                      sx={{
                        width: "100%",
                        borderRadius: "15px",
                        "& input::placeholder": {
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(156, 156, 156, 1)",
                        },
                      }}
                      {...register("email", { required: true })}
                      error={errors.email ? true : false}
                      helperText={errors.email && "Email is required"}
                      id="outlined-basic"
                      label="Enter your email.."
                      variant="outlined"
                      autoComplete="off"
                      InputProps={{
                        style: { borderRadius: "10px", fontSize: "14px" },
                      }}
                      InputLabelProps={{
                        style: { fontSize: "14px", fontFamily: "Montserrat" },
                      }}
                    />
                  </Grid>

                  <Grid
                    sx={{ marginBottom: "0.6rem" }}
                    item
                    xs={12}
                    sm={9}
                    md={9}
                    lg={9}
                  >
                    <p className="organization__name">Organization</p>
                    <TextField
                      size={"small"}
                      sx={{
                        width: "100%",
                        borderRadius: "15px",
                      }}
                      id="outlined-basic"
                      label="Enter your organization name.."
                      variant="outlined"
                      autoComplete="off"
                      InputProps={{
                        style: { borderRadius: "10px", fontSize: "14px" },
                      }}
                      InputLabelProps={{
                        style: { fontSize: "14px", fontFamily: "Montserrat" },
                      }}
                      {...register("organization", { required: true })}
                      error={errors.organization ? true : false}
                      helperText={
                        errors.organization && "Organization name is required"
                      }
                    />
                  </Grid>
                  <Grid
                    sx={{ marginBottom: "0.6rem" }}
                    item
                    xs={12}
                    sm={9}
                    md={9}
                    lg={9}
                  >
                    <p className="mobile__no">Mobile Number</p>
                    <Grid container spacing={1} alignItems="baseline">
                      <Grid item xs={12} sm={4} md={3} lg={3}>
                        <Autocomplete
                          size="small"
                          options={countries}
                          getOptionLabel={(option) => option.code}
                          value={selectedCountry}
                          onChange={(event, newValue) => {
                            setSelectedCountry(newValue);
                          }}
                          PaperComponent={({ children }) => (
                            <Paper
                              elevation={3}
                              sx={{
                                position: "absolute",
                                top: "auto",
                                maxHeight: "200px",
                                overflowY: "auto",
                              }}
                            >
                              {children}
                            </Paper>
                          )}
                          disableClearable
                          clearIcon={null}
                          sx={{
                            width: "100%",
                            borderRadius: "15px",
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id="outlined-basic-country-code"
                              variant="outlined"
                              InputProps={{
                                ...params.InputProps,
                                style: {
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                },
                              }}
                              InputLabelProps={{
                                style: { display: "none" },
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={8} md={9} lg={9}>
                        <TextField
                          size={"small"}
                          type="tel"
                          sx={{
                            width: "100%",
                            borderRadius: "15px",
                            "& input::placeholder": {
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(156, 156, 156, 1)",
                            },
                          }}
                          {...register("phoneNo", { required: true })}
                          error={errors.phoneNo ? true : false}
                          helperText={
                            errors.phoneNo && "Mobile number is required"
                          }
                          id="outlined-basic"
                          label="Enter your mobile number"
                          variant="outlined"
                          autoComplete="off"
                          InputProps={{
                            style: { borderRadius: "10px", fontSize: "14px" },
                          }}
                          InputLabelProps={{
                            style: {
                              fontSize: "14px",
                              fontFamily: "Montserrat",
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                 
                  <Grid
                    item
                    xs={12}
                    sm={9}
                    md={9}
                    lg={9}
                    sx={{ marginTop: "2rem" }}
                  >
                    <Button type="submit" className="login-btn">
                      Proceed
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              lg={4}
              className="right__side__form"
            >
              <img
                className="right_side_gif"
                width={"332px"}
                height={"588px"}
                src={loginGif}
                alt="gif"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box className="loginFirstScreen">
        <LoginFirstScreen />
      </Box>

      <Box
        className="loginFirstScreen1 "
        sx={{ backgroundColor: "rgba(246, 246, 246, 1)", position: "relative" }}
      >
        <LoginSecondPage />
      </Box>
    </>
  );
};

export default Login;
