import React from 'react';

import { createBrowserRouter,RouterProvider, } from 'react-router-dom'
import './App.css'
import Login from './Pages/Login';
import Layout from './Pages/Layout';
import LoginSecondPage from './Pages/LoginSecondPage';
import SpeechRecognitionComponent from './Pages/SpeechRecognitionComponent ';
function App() {

  // const AppRouter=createBrowserRouter([

  //   {
  //     path:'/',
  //     element:<Login>
  //   },
  // ])


  const AppRouter =createBrowserRouter([
    {
      path:'/',
      element:<Login/>
    },{
      path:'chatbot',
      element:<Layout/>
    },
    {
      path:"login",
      element:<SpeechRecognitionComponent/>
    }
  ])

  return (
  
     <>
     <RouterProvider router={AppRouter} />
       
      </>
  );
}

export default App;
