import React, { useRef } from "react";
import { Grid, Button, Box } from "@mui/material";
import loginGif from "../Assets/LoginRightSide.gif";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import DownArrow from "../Assets/DownArrow1.svg";
import { Link } from "react-router-dom";
const LoginFirstScreen = () => {

    // const scrollToBottom = () => {
    //     setTimeout(() => {
    //       const scrollableHeight = document.documentElement.scrollHeight - window.innerHeight;
    //       document.documentElement.scrollTop = scrollableHeight+500;
    //     }, 0);
    //   };

    function scrollToBottom() {
        // For cross-browser compatibility
        const scrollTop = (document.documentElement.scrollTop || document.body.scrollTop);
        const scrollHeight = (document.documentElement.scrollHeight || document.body.scrollHeight);
        const clientHeight = document.documentElement.clientHeight;
    
        const scrollPosition = scrollTop + clientHeight;
    
        // Scroll to the bottom smoothly
        window.scrollTo({
            top: scrollHeight,
            behavior: 'smooth'
        });
    }
      
      // Example usage: scrolling to 100% of the scrollable area
      
      

  return (
    <>
      <Grid
        className="login__first__screen"
        container
        justifyContent={"center"}
      >
        <Grid item xs={11} sm={9} md={10} lg={10}>
          <div style={{display:"flex",justifyContent:"center"}}>
          <img
            className="right_side_gif"
            width={"250px"}
            height={"440px"}
            src={loginGif}
            alt="gif"
          />
          </div>
          {/* <Box sx={{ marginTop: "1.4rem" }}>
            <Button
              className="login-btn"
              onClick={() => {
                scrollToBottom();
              }}
            >
              Experience Vifi AI
            </Button>
          </Box> */}
          {/* <Box display={"flex"} justifyContent={"center"} mt={3}> */}
            {/* <img style={{cursor:"pointer"}} onClick={()=>{scrollToBottom();}} src={DownArrow} alt='' /> */}
            <Box mt={4} onClick={()=>{scrollToBottom();}}>
            <div className="arrow-container animated fadeInDown">
              <div className="arrow-2">
                <i className="fa fa-angle-down" style={{color:"#fff"}}></i>
              </div>
              <div className="arrow-1 animated hinge infinite zoomIn"></div>
              
            </div>
            
            
            </Box>
            <Box display={"flex"} justifyContent={"center"} sx={{marginTop:"7.8rem"}}>
            <p className='login__secondpage__descriptions'>Experience Vifi AI</p>
            </Box>
            
          {/* </Box> */}


          
        </Grid>
      </Grid>
    </>
  );
};

export default LoginFirstScreen;
